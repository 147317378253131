import merge from 'lodash/merge';

import { IStudioOptions } from './interface';
import { checkElementVisible } from '../Tool/toolHtml';

//! Do not use lodash or axios as it weights a lot (around 20-30 KB)
// import axios from 'axios';
// import merge from 'lodash/merge';

export const quotereplacement = '|';

export const removeQuote = (optionString: string): string => {
    const optionArray = optionString.split('"');
    return optionArray.join(quotereplacement);
};

export const addQuote = (optionString: string): string => {
    const optionArray = optionString.split(quotereplacement);
    return optionArray.join('"');
};

let currentScript: HTMLScriptElement;
const getCurrentScript = () => {
    currentScript = document.currentScript;
    if (!currentScript) {
        (function () {
            const scripts = document.getElementsByTagName('script');
            currentScript = scripts[scripts.length - 1];
        }());
    }
};
getCurrentScript();

const checkScript = (callback: (projectOption: IStudioOptions) => void) => {
    if (!currentScript) return;
    let projectString = currentScript.dataset.option;
    if (!projectString) {
        projectString = currentScript.getAttribute('data-option');
    }

    if (projectString) {
        const projectJson = addQuote(projectString);
        let projectOption: IStudioOptions;
        try {
            projectOption = JSON.parse(projectJson);
            callback(projectOption);
        } catch {
            console.error('Naker: Bad Json');
        }
    }
};

const getContainer = (selector: string) => {
    let container = document.getElementById(selector);
    if (!container) container = document.querySelector(selector);
    return container;
};

const checkContainer = (callback: (el: HTMLElement) => void) => {
    if (!currentScript) return;

    const containerSelector = currentScript.dataset.container;
    if (containerSelector) {
        const container = getContainer(containerSelector);
        if (container) { // If container already there then go
            callback(container);
        } else { // Otherwise wait for the page to load
            window.addEventListener('load', () => {
                const container = getContainer(containerSelector);
                if (container) {
                    callback(container);
                } else { // If still no container, launch loop to check if it appears later
                    const intervalCheck = setInterval(() => {
                        const container = getContainer(containerSelector);
                        if (container) {
                            clearInterval(intervalCheck);
                            callback(container);
                        }
                    }, 100);
                    console.error('Naker: Bad selector, not able to find your container after page load');
                }
            });
        }
    } else { // If no container in data, check for the parent
        callback(currentScript.parentNode);
    }
};

const loadJson = (projectid: string, json: boolean, callback?: (json) => void) => {
    // TODO: Change URL to production, test with json = false
    // const BASE_URL = 'https://backend-dev.naker.io/project/view';
    const BASE_URL = 'https://backend-prod.naker.io/project/view';
    const query = `?json=${json}&id=${projectid}`;
    // 1. Create a new XMLHttpRequest object
    const xhr = new XMLHttpRequest();
    // 2. Configure it: GET-request for the URL /article/.../load
    xhr.open('GET', BASE_URL + query);

    // 3. Send the request over the network
    xhr.send();

    // 4. This will be called after the response is received
    xhr.onload = function () {
        if (xhr.status !== 200) { // analyze HTTP status of the response
            throw new Error(`Naker: Error loading project with id ${projectid}: ${xhr.status} ${xhr.statusText}`);
        } else if (xhr.readyState === 4) { // request finished
            try {
                json = JSON.parse(xhr.response);
                // console.log(json);
                if (callback) callback(json);
            } catch (e) {
                console.error(e);
                throw new Error(`Naker: Error parsing json response with id ${projectid}`);
            }
        }
    };

    xhr.onerror = function () {
        // throw new Error(`Naker: Error loading project with id ${projectid}`);
    };
};

export class ViewerLoader {
    private container: HTMLElement;

    constructor(studioOptions: IStudioOptions, callback?: () => void) {
        if (!studioOptions.container) throw Error('Naker : Missing container');
        if (!studioOptions.id) throw Error('Naker : Missing project id');
        if (studioOptions.container.tagName === 'HEAD') throw Error('Naker : container can not be head tag');
        this.container = studioOptions.container;

        if (studioOptions.container) {
            this.waitElementToBeVisible(() => {
                // If noo conotent, it means we don't have the project data in the json
                if (studioOptions.contents !== undefined) {
                    loadJson(studioOptions.id, false);
                    this.setEngineVersion(studioOptions.version);
                    this.loadProject(studioOptions, callback);
                } else if (studioOptions.id !== undefined) {
                    this.loadThreedJsonFromId(studioOptions, callback);
                }
            });
        }
    }

    private loadThreedJsonFromId(studioOptions: IStudioOptions, callback?: () => void) {
        loadJson(studioOptions.id, true, (response) => {
            this.setEngineVersion(response.version);
            // In order to keep original project parameter like AR, Config, etc
            studioOptions = merge(response, studioOptions);
            this.loadProject(studioOptions, callback);
        });
    }

    private loadProject(studioOptions: IStudioOptions, callback?: () => void) {
        this.loadStudioEngine((StudioEngine) => {
            new StudioEngine(studioOptions, callback);
        });
    }

    //! Need to keep this function which is reset in dev mode
    protected loadStudioEngine(callback: (engine) => void): void {
        this.loadEngineAsync(() => {
            // Once engine loaded, we will have the NakerStudioEngine new key available
            callback(window.NakerStudioEngine);
        });
    }

    protected engineVersion: string;

    private setEngineVersion(engineVersion: string) {
        this.engineVersion = engineVersion;
    }

    protected getScriptUrl(): string {
        const scriptUrlString = currentScript.src;
        const scriptUrlArray = scriptUrlString.split('/');
        scriptUrlArray.pop(); // Remove viewer.js
        if (this.engineVersion) { // Check version
            scriptUrlArray.pop();
            scriptUrlArray.push(`v${this.engineVersion}`);
        }
        const scriptUrl = `${scriptUrlArray.join('/')}/`;
        return scriptUrl;
    }

    private loadEngineAsync(callback: () => void) {
        const scriptUrl = this.getScriptUrl();
        const script = document.createElement('script');
        script.src = `${scriptUrl}engine.js`;
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            callback();
        });
    }

    private checkIfVisible = false;

    private waitElementToBeVisible(callback: () => void) {
        this.checkIfVisible = true;
        this.checkContainerVisible(callback);
        window.addEventListener('scroll', () => {
            this.checkContainerVisible(callback);
        });
    }

    private checkContainerVisible(callback: () => void) {
        if (!this.checkIfVisible) return;
        const containerVisible = checkElementVisible(this.container, 500);
        if (containerVisible) {
            callback();
            this.checkIfVisible = false;
        }
    }
}

checkScript((studioOptions) => {
    checkContainer((container) => {
        studioOptions.container = container;
        new ViewerLoader(studioOptions);
    });
});

window.NakerStudioViewer = ViewerLoader;
