import { setAttr } from 'redom';

export function checkElementVisible(element: HTMLElement, margin?: number): boolean {
    if (element === document.body) return true;
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    // is canvas on screen depending on scroll
    if (!margin) margin = 0;
    const onScreen = !(rect.bottom <= -margin || rect.top - viewHeight >= margin);
    // is parent display or not
    let parentDisplayed = true;
    if (element.offsetParent) {
        parentDisplayed = !!element.offsetParent;
    }
    const containerVisible = (onScreen && parentDisplayed);
    return containerVisible;
}

export const getDevice = (): 'iOS' | 'Androïd' | 'Computer' => {
    const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i);
    const isTablet = navigator.userAgent.toLowerCase().match(/tablet/i);
    const isAndroid = navigator.userAgent.toLowerCase().match(/android/i);
    const isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i);
    const isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);
    if (isiPhone || isiPad) {
        return 'iOS';
    } if (isMobile || isTablet || isAndroid) {
        return 'Androïd';
    }
    return 'Computer';
};

export const getUrlWithoutExtension = (url: string): string => url.substr(0, url.lastIndexOf('.') + 1);

export const getUrlExtension = (url: string): string => url.substr(url.lastIndexOf('.') + 1);

export const downloadFile = (file: string, name: string): void => {
    const link = document.createElement('a');
    link.setAttribute('href', file);
    link.setAttribute('download', name);
    link.click();
    link.remove();
};

export const svgStringToDom = (parent: HTMLElement, svg: string): void => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svg, 'application/xml');
    const iconHTML = doc.documentElement;
    setAttr(iconHTML, { fill: 'white' });
    parent.appendChild(
        parent.ownerDocument.importNode(iconHTML, true)
    );
};

export const getQueryParam = (param: string): string => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    return searchParams.get(param);
};
